.leaflet-container {
    width: 99vw;
    height: 95vh;
}

.sidebar {
    box-sizing: border-box;
    padding: 2em;
    width: 30vw;
}

.sidebar h2,
.sidebar p,
.sidebar ul {
    text-align: left;
}

.sidebar h2 {
    margin-top: 0;
}

.sidebar button {
    width: 100%;
    font-weight: bold;
    padding: .8em 1em;
}